/**
 * api 接口层拦截处理 滑块验证事项
 * */

import RequestPromise from 'common/apiRequest/Request/RequestPromise';
import AliCaptcha from 'common/components/AliCaptcha';
import message from 'common/components/Message';
import { CAPTCHA_ERROR } from 'common/constants/Constants';
import Utils from 'common/utils';
import JSRuntime from 'common/utils/JSRuntime';

const tencentTCaptchaClass = `OneID_TCaptcha`;
const aliCaptchaClass = `OneID_Ali_Captcha`;

const MAX_RETRY_COUNT = 3;

function retry(request: () => Promise<any>, onError: () => void) {
  return new Promise((resolve, reject) => {
    let count = 1;
    const tryFunc = async () => {
      try {
        // 请求
        await request();
        resolve({ message: 'request captcha success' });
      } catch ({ error }) {
        onError();
        const msg = `captcha脚本 ${error?.target?.src} 加载失败`;
        // 上报失败内容
        window.aegis?.report({ msg });
        // 请求失败，重试&重试次数 +1
        if (count < MAX_RETRY_COUNT) {
          tryFunc();
          count = count + 1;
        } else {
          message.error('服务出现了一些问题，请刷新页面或稍后重试');
          reject({ data: { errCode: CAPTCHA_ERROR, message: msg } });
        }
      }
    };
    tryFunc();
  });
}

function getTencentCaptcha() {
  // 如果有了 TCaptcha 引用，则不重复引用
  if (window?.TencentCaptcha) {
    return Promise.resolve();
  }
  return retry(
    () =>
      Utils.requireJS(`https://turing.captcha.qcloud.com/TCaptcha.js`, {
        className: tencentTCaptchaClass,
      }),
    () => {
      const scriptTags = document.getElementsByClassName(tencentTCaptchaClass);
      scriptTags[0]?.parentNode?.removeChild(scriptTags[0]);
    },
  );
}
function getAliCaptcha() {
  // 如果有了 Ali Captcha 引用，则不重复引用
  if (window?.AliCaptcha) {
    return Promise.resolve();
  }
  return retry(
    () =>
      Utils.requireJS(`${JSRuntime.cdnUrl}/static/js/AliCaptcha.js`, {
        className: aliCaptchaClass,
      }),
    () => {
      const aliScriptTags = document.getElementsByClassName(aliCaptchaClass);
      aliScriptTags[0]?.parentNode?.removeChild(aliScriptTags[0]);
    },
  );
}

export type TCaptchaOptions = {
  appId: string;
  cloudType: string;
  appKey: string;
  scene: string;
};

export type TCaptchaVerification =
  | { ticket: string; randStr: string }
  | { sessionId: string; sig: string; token: string };

enum CaptchaEvent {
  show = 'show',
}

type TEventCallBack = () => void;

class CaptchaPack {
  private captchaRef: TencentCaptcha | AliCaptcha | null = null;
  private eventsCallback: Record<CaptchaEvent, Set<TEventCallBack>> = {
    [CaptchaEvent.show]: new Set(),
  };
  public static Event = CaptchaEvent;
  public once = (event: CaptchaEvent, callbackOnce: TEventCallBack) => {
    if (!this.eventsCallback[event]) {
      this.eventsCallback[event] = new Set();
    }
    const func = () => {
      callbackOnce();
      this.eventsCallback[event].delete(func);
    };
    this.eventsCallback[event].add(func);
  };

  // 唯一对外输出方法
  public run = async (
    callback: (captchaVerification?: TCaptchaVerification) => RequestPromise<any>,
  ) => {
    const requestApi = callback();
    const [err, res] = await Utils.resolvePromise(requestApi);
    if (err) return requestApi;

    const successType = _.get(res, 'next.type');
    // 没触发滑块验证，直接返回数据
    if (successType !== 'CAPTCHA_OPTIONS') return requestApi;

    // 触发了滑块验证的场景
    const captchaOptions = _.get(res, 'next.captchaOptions');
    const [captchaErr, captchaVerification] = await Utils.resolvePromise(
      this.showCaptcha(captchaOptions),
    );
    if (captchaErr) {
      return new RequestPromise(Promise.reject(captchaErr), requestApi.abort);
    }

    return callback(captchaVerification);
  };

  private activeCaptcha = async (
    captchaAppId: string,
    onSuccess: (captchaVerification: TCaptchaVerification) => void,
    onError?: (res: any) => void,
  ) => {
    if (this.captchaRef && this.captchaRef instanceof TencentCaptcha) {
      this.captchaRef.destroy();
    }
    /**
     * 生成一个验证码对象
     * - captchaAppId: 登录验证码控制台，从【验证管理】页面进行查看。如果未创建过验证，请先新建验证。注意：不可使用客户端类型为小程序的CaptchaAppId，会导致数据统计错误。
     * - callback: 定义的回调函数
     */
    const captcha = new TencentCaptcha(
      captchaAppId,
      (res) => {
        // 继续登录
        if (m.get(res, 'ret') === 0) {
          onSuccess({
            ticket: _.get(res, 'ticket'),
            randStr: _.get(res, 'randstr'),
          });
        } else {
          onError?.(res);
        }
      },
      {},
    );
    this.captchaRef = captcha;
    // 调用方法，显示验证码
    captcha.show();
  };

  private activeAliCaptcha = (
    appKey: string,
    scene: string,
    onSuccess: (res: TCaptchaVerification) => void,
    onError?: (res: any) => void,
  ) => {
    if (this.captchaRef && this.captchaRef instanceof AliCaptcha) {
      this.captchaRef.hide();
    }
    // 生成一个验证码对象
    const captcha = new AliCaptcha(
      appKey,
      scene,
      (data: { sessionId: string; sig: string; token: string }) => {
        onSuccess(data); // 继续登录
      },
      (res) => {
        onError?.(res);
      },
    );
    this.captchaRef = captcha;
    // 调用方法，显示验证码
    captcha.show();
  };

  private showCaptcha = async (captchaOptions: TCaptchaOptions) => {
    const { cloudType, appId, scene } = captchaOptions;
    // 加载 Captcha 脚本
    const [err] = await Utils.resolvePromise(
      cloudType === 'tencent' ? getTencentCaptcha() : getAliCaptcha(),
    );
    if (err) return Promise.reject(err);

    // 执行 Captcha 脚本
    return new Promise((resolve, reject) => {
      this.eventsCallback?.[CaptchaEvent.show]?.forEach((func) => func());
      try {
        const errMessage = 'captcha 取消或发生错误';
        if (cloudType === 'tencent') {
          this.activeCaptcha(
            appId,
            (res) => resolve(res),
            (res) => {
              window.aegis?.report({ msg: errMessage });
              reject({
                data: { errCode: CAPTCHA_ERROR, errMessage, error: res },
              });
            },
          );
        } else {
          this.activeAliCaptcha(
            appId,
            scene,
            (res) => resolve(res),
            (res) => {
              window.aegis?.report({ msg: errMessage });
              reject({
                data: { errCode: CAPTCHA_ERROR, errMessage, error: res },
              });
            },
          );
        }
      } catch (error) {
        window.aegis?.report({ msg: 'captcha 实例调用失败' });
        message.error('服务出现了一些问题，请刷新页面或稍后重试');
        Promise.reject(error);
      }
    });
  };
}

export default CaptchaPack;
