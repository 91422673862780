import { TApiError } from 'common/apiRequest/Request';
import { AUTHENTICATION_METHOD, NO_COMPANY_ERROR } from 'common/constants/Constants';
import Utils from 'common/utils';
import JSRuntime from 'common/utils/JSRuntime';

type TError = TApiError['response'] | { status: number };

class ResponseInterceptors {
  public onSuccess = (response: { data: any }): Promise<any> => {
    if (JSRuntime.isProductSite)
      window.aegis?.info(`Response: ${Utils.aegisSuccessInfo(response)}`);

    return Promise.resolve(response.data);
  };

  public onError = (error: TApiError) => {
    // status = 0 断网
    const err = error.response ? error.response : { status: 0 };

    if (JSRuntime.isProductSite && err?.status >= 500)
      window.aegis?.error(Utils.aegisErrorInfo(error));

    return this.handleError(err);
  };

  public handleError = (error: TError): any => {
    const status = _.get(error, 'status');
    switch (status) {
      case 401:
        this.handleNoAuth();
        return Promise.reject(error);
      case 403:
        this.handleForbidden();
        return Promise.reject(error);
      case 412:
        this.handleNoCompany(NO_COMPANY_ERROR);
        return Promise.reject(error);
      default:
        return Promise.reject(error);
    }
  };

  // 自定义域名下，域名不可用则跳转到当前自定义域名的 IDP 错误页
  private handleNoCompany = (errCode: string) => {
    Utils.redirectToIdp(undefined, `/sso_login/error?errCode=${errCode}`);
  };

  // 401 退出登录
  private handleNoAuth = () => {
    const method = window.localStorage.getItem('authenticationMethod');
    if (JSRuntime.thirdLandingPage) {
      Utils.safeRedirect(JSRuntime.thirdLandingPage);
    } else if (method === AUTHENTICATION_METHOD.ENTERPRISE) {
      Utils.redirectToIdp();
    } else {
      Utils.redirectToOriginalIdp();
    }
  };

  // 403 返回个人页
  private handleForbidden = () => {
    if (JSRuntime.thirdLandingPage) {
      Utils.safeRedirect(JSRuntime.thirdLandingPage);
    } else {
      Utils.redirectToPortal();
    }
  };
}

export default new ResponseInterceptors();
