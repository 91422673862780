import BaseApi from 'common/apiRequest/apis/BaseApi';
import message from 'common/components/Message';
import { handleError } from 'common/utils/ErrorHandler';
import JSRuntime from 'common/utils/JSRuntime';
import { Request } from '../apiConcentrator/AdminV2';
import { Request as PersonRequest } from '../apiConcentrator/Person';
import APINames from '../APINames';

const ERROR_MSG_URL_WHITE_LIST = [`${APINames.ACCOUNT}/certify/tencentcloud`];
class AccountApi extends BaseApi {
  public requestInstance;

  constructor() {
    super();
    this.requestInstance = new Request(APINames.ACCOUNT);
    const {
      axiosInstance: {
        interceptors: { response },
      },
    } = this.requestInstance;
    response.use(undefined, (err) => {
      if (err.status === 500 && ERROR_MSG_URL_WHITE_LIST.includes(_.get(err, 'config.url'))) {
        return Promise.reject(err);
      }
      const ret = handleError(err);
      if (ret === null) {
        if (err.status === 403) {
          const content = err.data?.errMessage || '当前账号权限不足';
          message.error({ content });
          return err.data || err;
        }
        Promise.reject(err);
      }
    });
  }

  public personRequestInstance = new PersonRequest(APINames.ACCOUNT);

  /**
   * ocr图片识别
   * @returns
   */
  getOcr(params: { img_url: string; ocr_type: number }) {
    return this.requestInstance.get(['certify', 'ocr'], params);
  }

  sendSmsCode(phone: string) {
    return this.personRequestInstance.post(
      ['personal', 'certify', 'personal_account_job_certify_info_send_sms_code'],
      { phone },
    );
  }

  /**
   * 获取企业认证二维码
   * @returns
   */
  getQr() {
    return this.requestInstance.get(['certify', 'qr']);
  }

  /**
   * 认证信息授权
   * @param params
   * @returns
   */
  authorize(appType?: string) {
    return this.requestInstance.put(['certify', 'authorize'], {
      app_type: appType || JSRuntime.appFromUrl,
    });
  }
  /**
   * 获取当前app的授权信息
   * @returns
   */
  getAuthorize() {
    return this.requestInstance.get(['certify', 'authorize'], {
      app_type: JSRuntime.appFromUrl,
    });
  }
  /**
   * 获取全量的授权信息
   */
  getAllAuthorize() {
    return this.requestInstance.get(['certify', 'authorize']);
  }

  delAuthorize(appType?: string) {
    return this.requestInstance.del(['certify', 'authorize'], {
      app_type: appType || JSRuntime.appFromUrl,
      scene: 2,
    });
  }

  /**
   * 获取草稿信息
   * @param certify_type
   * @returns
   */
  getDraft(certifyType: number) {
    return this.requestInstance.get(['certify', 'draft'], {
      certify_type: certifyType,
    });
  }

  /**
   *
   *获取二维码状态
   */
  getQrStatus() {
    return this.requestInstance.get(['certify', 'qr', 'status']);
  }

  clearQRStatus() {
    return this.requestInstance.del(['certify', 'qr', ':clear']);
  }

  saveAuthInfo(params: Record<string, any>) {
    return this.requestInstance.post(['certify', 'apply_or_draft'], params);
  }

  getAuthInfo() {
    return this.requestInstance.get(['certify', 'apply']);
  }

  revokeAuth() {
    return this.requestInstance.del(['certify', 'apply']);
  }

  // 获取授权时的state
  getAccountState() {
    return this.requestInstance.get(['certify', 'state']);
  }
  // 获取腾讯云授权结果
  getAuthorizeResult(params: { code: string; state: string }) {
    return this.requestInstance.post(['certify', 'tencentcloud'], params);
  }

  delTencentCloud() {
    return this.requestInstance.del(['certify', 'tencentcloud']);
  }

  check(simple_name: string) {
    return this.requestInstance.get(['certify', 'simple_name', 'check'], { simple_name });
  }
}

export default new AccountApi();
