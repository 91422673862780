import { getGlobalReportParams } from './reportParams/getGlobalReportParams';
import { getUserReportParams } from './reportParams/getUserReportParams';
import { dealBeaconReport } from './beaconReport';

const beaconReport = (
  eventCode: string,
  globalParams?: Record<string, any>,
  userParams?: Record<string, any>,
) => {
  const params = {
    ...getUserReportParams(userParams),
    ...getGlobalReportParams(eventCode, globalParams),
  };
  if (_.isFunction(dealBeaconReport)) dealBeaconReport(eventCode, params);
};

export default beaconReport;
