// 数据上报SDK文档 https://iwiki.woa.com/p/490519070
import JSRuntime from 'common/utils/JSRuntime';
import beaconReport from './base';
import wemeetBeaconReport from './wemeet';

const BASE_DATA_REPORT_PREFIX = 'e#oneid_';

export enum APP_REPORT_KEY {
  WEMEET,
}

/**
 * oneid 数据上报
 * @param eventName 上报事件名称
 * @param globalParams 上报公参
 * @param params 上报私参
 * @returns
 */
const baseDataReport = (
  eventName: string,
  globalParams?: Record<string, any>,
  params?: Record<string, any>,
) => {
  // NOTICE 如果是私有化，不上报
  if (JSRuntime.isPrivate || !eventName) return;

  beaconReport(eventName, globalParams, params);
};

/**
 * 多应用 & oneid数据上报
 * @param eventName 上报事件名称
 * @param globalParams 上报公参
 * @param params 上报私参
 * @returns
 */
export const dataReport = (
  eventName: string,
  globalParams?: Record<string, any>,
  params?: Record<string, any>,
  options?: {
    [APP_REPORT_KEY.WEMEET]?: boolean;
  },
) => {
  // NOTICE 如果是私有化，不上报
  if (JSRuntime.isPrivate || !eventName) return;
  if (eventName.startsWith(BASE_DATA_REPORT_PREFIX)) {
    baseDataReport(eventName, globalParams, params);
    return;
  }

  if (eventName.startsWith('e#')) {
    const newEventName = eventName.replace('e#', BASE_DATA_REPORT_PREFIX);
    baseDataReport(newEventName, globalParams, params);
  }

  // 如果设置了meeting为false，则代表不上传，其他的都上传
  if (_.isBoolean(options?.[APP_REPORT_KEY.WEMEET]) && !options?.[APP_REPORT_KEY.WEMEET]) {
    return;
  }
  wemeetBeaconReport(eventName, globalParams, params);
};

/**
 * 上报
 */
export const delayDataReport = (
  eventName: string,
  globalParams?: Record<string, any>,
  params?: Record<string, any>,
  options?: {
    [APP_REPORT_KEY.WEMEET]?: boolean;
  },
) => {
  return new Promise((resolve) => {
    dataReport(eventName, globalParams, params, options);
    setTimeout(() => resolve(eventName), 300);
  });
};
