import 'common/utils/initEnv';
import 'common/store/initMobxConfig';
import { HashRouter } from 'react-router-dom';
import AegisV2 from '@tencent/aegis-web-sdk-v2';
import JSRuntime from 'common/utils/JSRuntime';
import globalStore, { Provider } from 'idpBase/store';
import RootContainer from './modules/root';
import 'common/styles/main.scss';
import 'tdesign-mobile-react/es/style/index.css'; // 少量公共样式
import './static/css/main.scss';
import 'animate.css';

if (JSRuntime.isProductSite) {
  const aegis = new AegisV2({
    id: 'SDK-69d659a949c29ce2ae13', // 项目ID，即上报id
    hostUrl: { url: 'https://galileotelemetry.tencent.com/collect' },
    plugin: {
      pv: true, // 默认开启pv收集器
      aid: true, // 默认开启aid收集器
      error: true, // 默认开启error收集器
      device: true, // 默认开启device收集器
      close: true, // 默认开启close收集器
      pagePerformance: true, // 默认开启pagePerformance收集器
      webVitals: true, // 默认开启webVitals收集器

      fId: true, // 默认关闭fId指纹插件
      ie: true, // 默认关闭ie兼容插件
      spa: true, // 默认关闭spa 上报插件
      apiSpeed: true, // 默认关闭api测速插件
      assetSpeed: true, // 默认关闭资源测速插件
      session: true, // 默认关闭session插件
      api: true,
    },
  });
  _.merge(window, { aegis });
}

ReactDOM.render(
  <Provider store={globalStore}>
    <React.Suspense fallback={<div>loading...</div>}>
      <HashRouter>
        <RootContainer />
      </HashRouter>
    </React.Suspense>
  </Provider>,
  document.getElementById('root'),
);
